<template>
  <div class="ca-page">
    <v-row class="ca-page-title">
      <v-col>
        <h1 class="ca-title">Sokal Spot Types</h1>
        <h2 class="ca-subtitle">Full Length</h2>
      </v-col>
    </v-row>
    <v-row class="ca-content">
      <v-col cols="12" lg="6">
        <div class="responsive-container">
          <iframe class="responsive-iframe"
            src="https://player.vimeo.com/video/683025513?h=37df81b5d0"
            width="100%"
            height="56.25%"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
          </div>
          <h2 class="text-center mt-5 mb-10">On Location Shoot</h2>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="responsive-container">
          <iframe class="responsive-iframe"
            src="https://player.vimeo.com/video/683024311?h=4f1ba33e0f"
            width="100%"
            height="650px"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
          </div>
          <h2 class="text-center mt-5 mb-10">Deal Card</h2>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="responsive-container">
          <iframe class="responsive-iframe"
            src="https://player.vimeo.com/video/683026169?h=8c211c4d20"
            width="100%"
            height="650px"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
          </div>
          <h2 class="text-center mt-5 mb-10">Spokesperson</h2>

        </v-col>
      <v-col cols="12" lg="6">
        <div class="responsive-container">
          <iframe class="responsive-iframe"
            src="https://player.vimeo.com/video/683024816?h=b7d00dd1b0"
            width="100%"
            height="650px"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
          ></iframe>
          </div>
          <h2 class="text-center mt-5 mb-10">Graphic-Centered</h2>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0);
  }
};
</script>

<style>
.responsive-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>